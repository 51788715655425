import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { ToastLevel, useToast } from '../ToastContext/ToastContext';
import { SofClientContext } from '../../apolloClient/sofApolloClient/SofClientContext';
import {
  Organization,
  useGetOrganizationsLazyQuery,
  useGetTransportItemTypesLazyQuery,
  TransportItemType,
  useGetAllBrandsLazyQuery,
  KeyDescription,
  useStoreUserContextMutation,
  useGetUserContextLazyQuery,
  SustainabilityScoreInput,
  useGetSustainabilityScoreDefaultsLazyQuery,
  useGetSustainabilityScoresLazyQuery,
} from '../../generated/sof-graphql';
import { sofApplicationContextReducer, SofApplicationContextState } from './reducer';
import { SofApplicationContextActionTypes } from './actions';
import { decodeJWT } from '../../utils/jwt';
import { useIntl } from 'react-intl';
import messages from '../../messages';
import { TeraloRoute, routesMap } from '../../components/PageRoutes/routes';
import { useNavigate } from 'react-router-dom';
import { CognitoUser } from '../../hooks/auth/useAuth';
import { useLocation } from 'react-router-dom';
import { checkPermissions } from '../../utils/permissions';
import { RightType } from '@volvogroup-internal/sof-authorization-client/openapi';
import { useDashboardContext } from '../DashboardFilterContext/DashboardFilterContext';

export const initialState: SofApplicationContextState = {
  organizations: [],
  activeOrganization: null,
  transportItemTypes: [],
  vehicleBrands: [],
  orgRoles: [],
  sustainabilityScores: [],
};

export const SofApplicationContext = createContext<{
  state: SofApplicationContextState;
  contextFetched: boolean;
  clearApplicationContext: () => void;
  setActiveOrgAndOrgRoles: (activeOrgId: string, token: string) => Promise<boolean>;
  setScores: (scores: SustainabilityScoreInput[]) => void;
}>({
  state: initialState,
  contextFetched: false,
  clearApplicationContext: () => null,
  setActiveOrgAndOrgRoles: () => Promise.resolve(false),
  setScores: () => null,
});

interface Props {
  children: React.ReactNode;
  token?: string;
  currentUser: CognitoUser | null;
  mockState?: SofApplicationContextState; // provides a mock context for testing purposes, since there is no logged in user providing a JWT token in unit tests etc.
  mockContextFetched?: boolean; // provides a mock contextFetched value for testing purposes
}

export const SofApplicationContextProvider: React.FC<Props> = ({
  children,
  token,
  currentUser,
  mockState,
  mockContextFetched,
}) => {
  const [state, dispatch] = useReducer(sofApplicationContextReducer, initialState);
  const { client } = useContext(SofClientContext);

  const [getOrganizations] = useGetOrganizationsLazyQuery({ client: client || undefined });

  const [getTransportItemTypes] = useGetTransportItemTypesLazyQuery({
    client: client || undefined,
  });
  const [getAllBrands] = useGetAllBrandsLazyQuery({ client: client || undefined });

  const [getDefaultScores] = useGetSustainabilityScoreDefaultsLazyQuery({
    client: client || undefined,
    fetchPolicy: 'network-only',
  });
  const [getOrgScores] = useGetSustainabilityScoresLazyQuery({
    client: client || undefined,
    fetchPolicy: 'network-only',
  });

  const [getUserContext] = useGetUserContextLazyQuery({ client: client || undefined });
  const [storeUserContext] = useStoreUserContextMutation();
  const { formatMessage } = useIntl();
  const location = useLocation();
  const { resetContextFilters } = useDashboardContext();

  const [contextFetched, setContextFetched] = useState(false);

  const navigate = useNavigate();

  const { openToast } = useToast();

  useEffect(() => {
    if (mockState) {
      dispatch({ type: SofApplicationContextActionTypes.UPDATE, payload: mockState }); // updates the state based on the provided mockState
    }
  }, [mockState]);

  useEffect(() => {
    if (mockContextFetched) {
      setContextFetched(mockContextFetched); // updates the contextFetched value based on the provided mockContextFetched
    }
  }, [mockContextFetched]);

  /**
   * Retrieves the organization roles from a decoded JWT token for the active organization.
   * @param decodedJWT - The decoded JWT token.
   * @param activeOrg - The active organization.
   * @returns An array of organization roles.
   */
  const getOrgRolesFromJWT = useCallback(
    (decodedJWT: Record<string, any>, activeOrgId: string): string[] => {
      if (!activeOrgId || !decodedJWT) return [];

      if (!decodedJWT[`org_${activeOrgId}`]) {
        navigate(routesMap[TeraloRoute.LOGIN]);
        return [];
      }

      try {
        let selectedOrg = `org_${activeOrgId}`;
        let orgRolesString: string = decodedJWT[selectedOrg];

        let orgRoles: string[] = orgRolesString.split(',');

        return orgRoles;
      } catch (error) {
        console.error('Error: Could not get org roles: ' + error);
        openToast(`${formatMessage(messages.error_fetching_org_roles)}: ${error}`, ToastLevel.ERROR);
        return [];
      }
    },
    [openToast, formatMessage, navigate],
  );

  const setScores = useCallback(
    (scores: SustainabilityScoreInput[]) => {
      dispatch({
        type: SofApplicationContextActionTypes.UPDATE,
        payload: {
          ...state,
          sustainabilityScores: scores,
        },
      });
    },
    [state],
  );

  const getSavedOrDefaultScores = useCallback(
    async (orgId: string, orgRoles: string[]): Promise<SustainabilityScoreInput[]> => {
      let scores: SustainabilityScoreInput[] = [];
      if (checkPermissions([RightType.GET_SUSTAINABILITY_SCORE], orgRoles)) {
        const fetchDefaultScores = async () => {
          const defaultScoresResult = await getDefaultScores();
          return (
            defaultScoresResult?.data?.getSustainabilityScoreDefaults.map(({ score, threshold }) => ({
              score,
              threshold,
            })) || []
          );
        };
        try {
          const result = await getOrgScores({
            variables: { orgId },
          });
          if (result?.data?.getSustainabilityScores && result.data.getSustainabilityScores.length > 0) {
            scores = result.data.getSustainabilityScores.map(({ score, threshold }) => ({
              score,
              threshold,
            }));
          } else {
            // Fallback to default scores if no org scores exist
            scores = await fetchDefaultScores();
          }
        } catch (error) {
          console.error(`Could not fetch threshold values: ${error}`);
          openToast(`${formatMessage(messages.error_fetching_threshold_values)}: ${error}`, ToastLevel.ERROR);
          // Fallback to default scores if there's an error in fetching org scores
          scores = await fetchDefaultScores();
        }
      }
      return scores;
    },
    [getOrgScores, getDefaultScores, formatMessage, openToast],
  );

  /**
   * Performs necessary data fetching (orgs, brands, transportItemTypes) and updates ApplicationContext.
   * @param activeOrg - The active organization.
   * @param token - The user token.
   */
  const populateApplicationContext = useCallback(
    async (activeOrgId: string, orgRoles: string[]) => {
      try {
        const [organizationsResult, brandsResult, transportItemTypesResult] = await Promise.all([
          getOrganizations(),
          getAllBrands(),
          getTransportItemTypes(),
        ]);

        if (organizationsResult.error) {
          console.error(`Could not fetch organizations: ${organizationsResult.error}`);
          openToast(
            `${formatMessage(messages.error_fetching_organizations)}: ${organizationsResult.error}`,
            ToastLevel.ERROR,
          );
        }

        if (brandsResult.error) {
          console.error(`Could not fetch brands: ${brandsResult.error}`);
          openToast(`${formatMessage(messages.error_fetching_brands)}: ${brandsResult.error}`, ToastLevel.ERROR);
        }

        if (transportItemTypesResult.error) {
          console.error(`Could not fetch transport item types: ${transportItemTypesResult.error}`);
          openToast(
            `${formatMessage(messages.error_fetching_transport_item_types)}: ${transportItemTypesResult.error}`,
            ToastLevel.ERROR,
          );
        }

        const activeOrg = organizationsResult?.data?.getOrganizations.items.find(
          (org) => org?.id === activeOrgId,
        ) as Organization;

        const scores = await getSavedOrDefaultScores(activeOrgId, orgRoles);

        if (activeOrg && !activeOrg.active) {
          openToast(formatMessage(messages.error_organization_not_active), ToastLevel.ERROR);
          navigate(routesMap[TeraloRoute.LOGIN]);
          return;
        }

        let vehicleBrands: KeyDescription[] = brandsResult?.data
          ? (brandsResult?.data?.getAllBrands.items as KeyDescription[])
          : [];

        if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_STAGE !== 'prod') {
          // for development purposes, add a soft car brand in the list
          vehicleBrands = [
            ...vehicleBrands,
            { key: 'TISP_SOFT_CAR', description: 'TISP Soft Car' },
            { key: 'ATLAS', description: 'Atlas' },
          ];
        }

        dispatch({
          type: SofApplicationContextActionTypes.UPDATE,
          payload: {
            ...state,
            organizations: organizationsResult?.data
              ? (organizationsResult?.data?.getOrganizations.items as Organization[])
              : [],
            transportItemTypes: transportItemTypesResult?.data
              ? (transportItemTypesResult?.data?.getTransportItemTypes as TransportItemType[])
              : [],
            vehicleBrands: vehicleBrands,
            activeOrganization: activeOrg ? (activeOrg as Organization) : null,
            orgRoles: orgRoles ? orgRoles : [],
            sustainabilityScores: scores ? (scores as SustainabilityScoreInput[]) : [],
          },
        });

        setContextFetched(true);
      } catch (error) {
        console.error('Error populating context:', error);
      }
    },
    [
      getOrganizations,
      getAllBrands,
      getTransportItemTypes,
      state,
      formatMessage,
      openToast,
      navigate,
      getSavedOrDefaultScores,
    ],
  );

  /**
   * Sets the active organization and associated roles based on the provided token as well as populating the Application Context. Also resets the Dashboard context filters.
   *
   * @param {Organization} activeOrg - The organization to set as active.
   * @param {string} token - The JWT token containing relevant information.
   * @returns {Promise<string>} - A promise that resolves when the operation is completed successfully.
   * @throws {Error} - If any errors occur during the process.
   */
  const setActiveOrgAndOrgRoles = useCallback(
    async (activeOrgId: string, token: string): Promise<boolean> => {
      resetContextFilters();

      return new Promise(async (resolve, reject) => {
        try {
          const decodedJWT = decodeJWT(token);
          const orgRoles = getOrgRolesFromJWT(decodedJWT, activeOrgId);

          await populateApplicationContext(activeOrgId, orgRoles);

          resolve(true);
        } catch (error) {
          console.error('Error in setActiveOrgAndOrgRoles:', error);
          navigate(routesMap[TeraloRoute.LOGIN]);
          reject(false);
        }
      });
    },

    [getOrgRolesFromJWT, populateApplicationContext, navigate, resetContextFilters],
  );

  useEffect(() => {
    // Routes that should not trigger the context fetching
    const routesToIgnore: string[] = [
      routesMap[TeraloRoute.LOGIN],
      routesMap[TeraloRoute.SIGNUP],
      routesMap[TeraloRoute.FORGOT_PASSWORD],
      routesMap[TeraloRoute.SELECT_ORGANISATION],
      routesMap[TeraloRoute.CHANGE_PASSWORD],
      routesMap[TeraloRoute.SET_PASSWORD],
      routesMap[TeraloRoute.CONFIRMORGACCOUNT],
    ];
    if (!contextFetched) {
      if (routesToIgnore.some((route) => location.pathname.includes(route))) {
        return;
      } else {
        (async () => {
          const context = await getUserContext();
          if (context.data?.getUserContext) {
            if (!context.data.getUserContext.activeOrgId) {
              navigate(routesMap[TeraloRoute.LOGIN]);
            }

            try {
              const activeOrgId = context.data.getUserContext.activeOrgId;

              if (activeOrgId && currentUser && token) {
                await setActiveOrgAndOrgRoles(activeOrgId, token);
              }
            } catch (error) {
              navigate(routesMap[TeraloRoute.LOGIN]);
            }
          } else {
            navigate(routesMap[TeraloRoute.LOGIN]);
          }
        })();
      }
    }
    if (contextFetched && token && currentUser && location.pathname === routesMap[TeraloRoute.LOGIN]) {
      navigate(routesMap[TeraloRoute.DASHBOARD]);
    }
  }, [
    token,
    contextFetched,
    storeUserContext,
    getUserContext,
    navigate,
    setActiveOrgAndOrgRoles,
    populateApplicationContext,
    currentUser,
    location.pathname,
    getSavedOrDefaultScores,
  ]);

  const clearApplicationContext = () => {
    dispatch({
      type: SofApplicationContextActionTypes.CLEAR,
    });
    setContextFetched(false);
  };

  const value = useMemo(
    () => ({
      state: state,
      setActiveOrgAndOrgRoles,
      contextFetched,
      clearApplicationContext,
      setScores,
    }),
    [state, contextFetched, setActiveOrgAndOrgRoles, setScores],
  );

  return <SofApplicationContext.Provider value={value}>{children}</SofApplicationContext.Provider>;
};
